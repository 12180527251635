import React, { useContext } from 'react';
import { navigate } from '@reach/router';

import Button from '../Button/Button';

import Bricklayer from '../../assets/illustrations/bricklayer.svg';
import Check from '../../assets/illustrations/check.svg';

import { TRANSLATE } from '../../constants/languages';

import context from '../../context/context';

import './ThankYou.scss';

const ThankYou: React.FC = (): JSX.Element => {
  const { language } = useContext(context);

  return (
    <div className="thanksPage">
      <div className="thanksPage__content">
        <Check />
        <h2>{TRANSLATE[language as 'ru' | 'ua'].thankYouTitle}</h2>
        <p>{TRANSLATE[language as 'ru' | 'ua'].thankYouText}</p>
        <Button click={() => navigate('/')} height={50} type="primary" htmlType="button" color="blue" width={160}>
          {TRANSLATE[language as 'ru' | 'ua'].returnToHome}
        </Button>
      </div>
      <div className="thanksPage__icon">
        <Bricklayer />
      </div>
    </div>
  );
};

export default ThankYou;
