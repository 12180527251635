/* eslint-disable react/button-has-type */
import React from 'react';

import { IButtonProps } from './Types';

import './Button.scss';

const Button: React.FC<IButtonProps> = ({ click, height, htmlType, type, width, color, children, disabled }): JSX.Element => (
  <button
    className={`button button-${type} ${color} ${disabled ? 'disabled' : ''}`}
    onClick={click}
    type={htmlType}
    style={width && height && { width, height }}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
